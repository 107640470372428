import React, {FunctionComponent} from 'react';
import Sidebar from '../components/Sidebar';
import {graphql, PageProps} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import LoginWrapper from '../components/LoginWrapper';
import HeroImage from '../components/HeroImage';
import HtmlHead from '../components/layout/HtmlHead';
import Images from '../types/Images';
import Files from '../types/Files';
import MDX from '../types/MDX';
import Anchor from '../components/Anchor';
import {usePermissionData} from '../lib/permissions/PermissionContext';

interface Download {
    title: string;
    id: string;
    to: string;
    external?: boolean;
}

type MarkdownPageProps = PageProps<{
    mdx: MDX;
    images: Images;
    files: Files;
}>;

const Downloads: FunctionComponent<MarkdownPageProps> = (props) => {
    require('../styles/global.scss');
    require(`../styles/${process.env.THEME}/global.scss`);
    const downloadImages = props.data.images;
    const downloadFiles = props.data.files;
    const {mdx} = props.data;
    const {body, frontmatter} = mdx;
    const {title, euLogin, bigLogin, category, loginButtonText} = frontmatter;
    const downloads: Array<Download> = require(`../content/${process.env.THEME}/${frontmatter.slug}.json`).downloads;
    const {state} = usePermissionData();
    const big:string = state.bigCode;
    const bigValid = big !== null;
    const mappedDownloads = downloads.map((download) => {
        const image = downloadImages.edges.find((edge) => edge.node.name === download.id);
        const file = downloadFiles.edges.find((edge) => edge.node.name === download.id)?.node.publicURL;

        return {
            ...download,
            image: image?.node.childImageSharp.gatsbyImageData,
            to: (bigValid) ? 'https://pro.campus.sanofi/nl/dyslipidemie/hulpmiddelen/praluent-downloads?restrictedaccess=cac' : download.to ?? (bigValid) ? 'https://pro.campus.sanofi/nl/dyslipidemie/hulpmiddelen/praluent-downloads?restrictedaccess=cac' : file,
            external: download.to && !download.to.startsWith('/')
        };
    });

    return (
        <>
            <HtmlHead pageTitle={title}/>
            <LoginWrapper
                title={title}
                euLogin={euLogin}
                bigLogin={bigLogin}
                buttonText={loginButtonText}
                category={category}
                heroImage={true}
                markdown={body}
            >
                <HeroImage/>
                <div className='px-4 lg:flex lg:stackx-8 lg:mt-12 lg:px-20 max-w-screen-2xl mx-auto downloads page'>
                    <div className='w-1/4'>
                        <Sidebar category={category}/>
                    </div>
                    <main className='my-6 w-full lg:w-3/4'>
                        <h1 className={'text-4xl font-bold my-6'}>{frontmatter.title}</h1>
                        <MDXRenderer>{body}</MDXRenderer>
                        <div className='grid lg:grid-cols-3'>
                            {
                                mappedDownloads.map((download) => {
                                    return (
                                        <div key={download.id} className='flex flex-col items-center mb-16 lg:pr-8'>
                                            <h2 className='font-bold text-gray-700 text-left w-full'>{download.title}</h2>
                                            {
                                                download.image &&
                                                <div className='max-w-md download-image'>
                                                    <GatsbyImage
                                                        image={download.image}
                                                        alt={download.title}
                                                    />
                                                </div>
                                            }
                                            {
                                                download.external ?
                                                    <Anchor
                                                        to={(bigValid) ? 'https://pro.campus.sanofi/nl/dyslipidemie/hulpmiddelen/praluent-downloads?restrictedaccess=cac' : download.to}
                                                        className='download-button px-6 py-2 font-bold uppercase border rounded-md hover:bg-transparent mt-4'
                                                    >
                                                        Download
                                                    </Anchor>
                                                    :
                                                    <a
                                                        href={(bigValid) ? 'https://pro.campus.sanofi/nl/dyslipidemie/hulpmiddelen/praluent-downloads?restrictedaccess=cac' : download.to}
                                                        className='download-button px-6 py-2 font-bold uppercase border rounded-md hover:bg-transparent mt-4'
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        Download
                                                    </a>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </main>
                </div>
            </LoginWrapper>
        </>
    );
};

export default Downloads;

export const pageQuery = graphql`
    query($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            frontmatter {
                slug
                title
                category
                euLogin
                bigLogin
                loginButtonText
            }
        }
        files: allFile(filter: {
                    extension: {eq: "pdf"}, 
            }) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
  images: allFile(filter: {
            relativeDirectory: { regex: "/(downloads)/"}
        }) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(quality: 90)
                    }
                }
            }
        }
    }
`;
